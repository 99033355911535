/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from "react";
import { graphql, PageProps, Link, navigate } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Hero } from "../../components";
// import { Hero, Statement } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import socialHead2 from "../../images/insighthand.jpg";
// import { GA_insightheader } from "../../components/GA_insightheader";
// import { Researchblock } from "../../components/DownloadBlock";
// import { GA_insightarticles } from "../../components/GA_insightarticles";
import { Helmet } from "react-helmet";
import linkedIcon from "../../images/Linkedin_circle vector.svg";
import instagram from "../../images/Instagram_circle vector.svg";
const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
  location,
}) => {
  const [isThankYou, setIsThankYou] = useState(false);
  console.log("location.state");
  console.log(location.state?.fromContactPage);
  console.log("**********");
  useEffect(() => {
    // Check if the type parameter is present in the POST request body
    // const requestBody = new URLSearchParams(window.location.search);
    // const typeParam = requestBody.get("type");
    // Check if the state indicates that it's from the contact page
    if (location.state?.fromContactPage) {
      setIsThankYou(true);
    } else {
      // If not from the contact page, redirect to the home page
      navigate("/en");
    }
  }, [location.state]); // Include location.state in the dependency array
  if (!isThankYou) return null;
  nodes.map((n) => console.log(n.id));
  // if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="Insights | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Thank you"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <section className="py-section  bg-white-1 pb-20px">
        <center>
          <div className="">
            <Helmet>
              <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
              <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
            </Helmet>
            <h2>
              <strong>Thank you for contacting us</strong>
            </h2>
            <h3 className="ga-thankyou-content-w">
              We have received your message and have sent a confirmation email
              (please check your spam folder). Our team will be in touch soon to
              see how we can help you.​
            </h3>
          </div>
          <br></br>
          <div className="lg:grid-cols-3 md:grid-cols-3 grid-cols-1 page-grid w-1/2">
            <div className="mt-10px">
              <Link to="/en" className="lg:float-right float-none">
                <button className="w-100px lg:w-auto col-start-4 bg-purple text-white-1 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center ga-thankyou-CTA">
                  Home
                </button>
              </Link>
            </div>
            <div className="mt-10px">
              <Link to="/en/about-us" className="">
                <button className="w-100px lg:w-auto col-start-4 bg-purple text-white-1 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center ga-thankyou-CTA">
                  About us
                </button>
              </Link>
            </div>
            <div className="mt-10px">
              <Link to="/en/press" className="lg:float-left float-none">
                <button className="w-100px  lg:w-auto col-start-4 bg-purple text-white-1 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center ga-thankyou-CTA">
                  Press
                </button>
              </Link>
            </div>
          </div>
          {/* <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            Launch demo modal
          </button> */}
        </center>
      </section>

      <>
        <section className="py-section padding8_5 bg-white-1 pb-20px text-center thankyou-p-70">
          <p>
            <strong>Follow us for news and insights </strong>
          </p>
          <div className="page-grid grid-cols-2">
            <div className="">
              <a
                href="https://www.instagram.com/lifeatpip/"
                className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg  float-right"
              >
                <img
                  src={instagram}
                  alt="Left"
                  className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap lg:w-50px w-80px ga-thankyou-mobile-social-icon-w-80"
                />
              </a>
            </div>
            <div className="">
              <a
                href="https://www.linkedin.com/company/partnersinperformance/"
                className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg float-left"
              >
                <img
                  src={linkedIcon}
                  alt="Left"
                  className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap lg:w-50px w-100px ga-thankyou-mobile-social-icon-w-80"
                />
              </a>
            </div>
          </div>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
